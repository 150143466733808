// Project imports
import MultipleTabsCheck from "./components/multipleTabsCheck";
import SoftwareVersionCheck from "./components/softwareVersionCheck";
import useSoMeAccounts from "./hooks/useSoMeAccounts";

const EnvironmentMonitor = () => {
  // Setup SoMe accounts
  useSoMeAccounts();

  return (
    <>
      <SoftwareVersionCheck />
      <MultipleTabsCheck />
    </>
  );
};

export default EnvironmentMonitor;
