import { useState, ReactNode, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProductFruits, useProductFruitsApi } from "react-product-fruits";
import { Crisp } from "crisp-sdk-web";

// material-ui
import {
  Box,
  Divider,
  Step,
  Stepper,
  StepLabel,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Project imports
import Welcome from "./welcome";
import LearnAboutUs, { LearnAboutUsAnswer } from "./learnAboutUs";
import CreateOrg, { BusinessRegNo } from "./createOrg";
import Questionnaire, { QuestionnaireAnswers } from "./questionnaire";
import Button from "components/@extended/LoadingButton";
import useAuth from "hooks/useAuth";
import { useTranslation } from "utils/locales/utilityFunctions";
import { OnboardingObject } from "types/auth";
import useStateRef from "react-usestateref";
import useUserActivity from "hooks/useUserActivity";
import { googlePlacesAutocompleteLoaded } from "utils/scriptLoader";
import { PF_WORKSPACE_CODE } from "config/config";
import { ERROR_REPORTING_EMAIL } from "config/config-general";
import useConfig from "hooks/useConfig";
import { getProductFruitsTourUrl } from "config/servicesConfig/productFruits";
import { openDrawer } from "store/reducers/menu";
import mapLocationPathToPageView from "services/mixpanel/mapLocationPathToPageView";

// --- Get step content logic ---//
const getStepContent = (
  step: number,
  handleNext: () => void,
  handleBack: () => void,
  setErrorIndex: (i: number | null) => void,

  learnAboutUsAnswer: LearnAboutUsAnswer,
  setLearnAboutUsAnswer: (d: LearnAboutUsAnswer) => void,

  businessRegNo: BusinessRegNo,
  setBusinessRegNo: (d: BusinessRegNo) => void,

  questionnaireAnswers: QuestionnaireAnswers,
  setQuestionnaireAnswers: (values: QuestionnaireAnswers) => void,
  disableBackButton?: boolean,
  userOnHoldBusinessExists?: boolean
) => {
  switch (step) {
    case 0:
      return <Welcome />;
    case 1:
      return (
        <LearnAboutUs
          handleNext={handleNext}
          handleBack={handleBack}
          disableBackButton={disableBackButton}
          setErrorIndex={setErrorIndex}
          learnAboutUsAnswer={learnAboutUsAnswer}
          setLearnAboutUsAnswer={setLearnAboutUsAnswer}
        />
      );
    case 2:
      if (!googlePlacesAutocompleteLoaded) return null;
      return (
        <CreateOrg
          handleNext={handleNext}
          handleBack={handleBack}
          disableBackButton={disableBackButton}
          setErrorIndex={setErrorIndex}
          businessRegNo={businessRegNo}
          setBusinessRegNo={setBusinessRegNo}
          userOnHoldBusinessExists={userOnHoldBusinessExists}
        />
      );
    case 3:
      return (
        <Questionnaire
          handleNext={handleNext}
          handleBack={handleBack}
          disableBackButton={disableBackButton}
          setErrorIndex={setErrorIndex}
          questionnaireAnswers={questionnaireAnswers}
          setQuestionnaireAnswers={setQuestionnaireAnswers}
        />
      );
    default:
      throw new Error("Unknown step");
  }
};

// ==============================|| ONBOARDING WIZARD ||============================== //

const Onboarding = () => {
  
  // --- Hooks --- //
  const dispatch = useDispatch();
  const {
    user,
    userOnboardingObject,
    userOrg,
    updateUserOnboardingObject,
    updateUserSettings,
    updateOrganization,
    updateProfile,
    sendEmail,
    callCloudFunction,
  } = useAuth();
  const { i18n } = useConfig();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = matchDownLG;

  useUserActivity({ user: user, updateUserSettings });
  // Set up onboarding step titles
  const steps = [
    { id: "welcome", label: translate("onboarding-steps-title-welcome") },
    { id: "about-us", label: translate("onboarding-steps-title-about-us") },
    { id: "company", label: translate("onboarding-steps-title-company") },
    { id: "needs", label: translate("onboarding-steps-title-your-needs") },
  ];

  // --- States and references --- //
  const disableBackStep = useRef<number>(0);
  const userOnHoldBusinessExists = useRef(false);
  const userPFRef = useRef<any | null>(null);
  const pfTourFinishedCallback = useRef<any | null>(null);
  const [loadingTimerState, setLoadingTimerState] = useState(true);
  const [showProductFruits, setShowProductFruits] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const [learnAboutUsAnswer, setLearnAboutUsAnswer, learnAboutUsAnswerRef] =
    useStateRef<LearnAboutUsAnswer>({ answer: "" });
  const [businessRegNo, setBusinessRegNo, businessRegNoRef] = useStateRef<BusinessRegNo>({
    businessNo: "",
    data: null,
    businessExists: false,
  });
  const [questionnaireAnswers, setQuestionnaireAnswers, questionnaireAnswersRef] =
    useStateRef<QuestionnaireAnswers>({
      role: "",
      estates: "",
      hectares: "",
      purposes: [],
      roleId: "",
      estatesId: "",
      hectaresId: "",
      purposesIds: [],
    });
  const [errorIndex, setErrorIndex] = useState<number | null>(null);

  // --- Effects --- //
  // Report page views to mixpanel
  useEffect(() => {
    if (location.pathname) {
      const page = mapLocationPathToPageView(location.pathname);
      // mixpanel.track(page);
      callCloudFunction("trackEvent", {event: page});
    }
  }, [location.pathname]);
  // Product fruits initialization
  useEffect(() => {
    // Toggle the rendering state to force reinitialization
    setShowProductFruits(false);
    const timer = setTimeout(() => {
      setShowProductFruits(true);
    }, 100); // Short delay to ensure proper unmounting and remounting
    // Cleanup function to clear the timer
    return () => {
      clearTimeout(timer);
      if (pfTourFinishedCallback.current) {
        pfTourFinishedCallback.current();
        pfTourFinishedCallback.current = null;
      }
    };
  }, [i18n]);

  useEffect(() => {
    if (user && !userPFRef.current) {
      userPFRef.current = {
        username: user.id, // REQUIRED - any unique user identifier
        email: user.email,
        firstname: user.firstName,
        lastname: user.lastName,
        signUpAt: user.creationDate,
        role: user.orgRole,
        // props: { customProp1: '==REPLACE==' }
      };
    }
  }, [user]);

  // Set loading timer
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTimerState(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (userOnboardingObject) {
      if (userOnboardingObject.step) {
        setActiveStep(userOnboardingObject.step);
        disableBackStep.current = userOnboardingObject.step;
      }
      if (userOnboardingObject.businessRegNoAndName?.businessExists) {
        userOnHoldBusinessExists.current = true;
      }
    }
  }, [userOnboardingObject, user]);

  // Logout user if they are on hold and being deleted
  useEffect(() => {
    if (userOnHoldBusinessExists.current) {
      // if (!user || (!("acceptedTerms" in user))) {
      //    handleLogout();
      // }
    }
    return () => {};
  }, [user, userOnHoldBusinessExists.current]);

  // --- Handle logout if user is deleted --- //
  async function handleLogout() {
    // try {
    //    userOnHoldBusinessExists.current = false;
    //    await logout();
    // } catch (err) {
    //    console.error(err);
    // }
  }

  // --- Product fruits functionality --- //
  useProductFruitsApi(
    (api) => {
      // Listen for tour finished event
      if (!pfTourFinishedCallback.current) {
        pfTourFinishedCallback.current = api.tours.listen("tour-finished", (tourId: number) => {
          const pfTours = api.tours.getTours();
          const pfTourCompleted = pfTours.find((t: any) => t.id === tourId);
          // Check for pre-tour type and start tour with the same name
          if (!pfTourCompleted || !pfTourCompleted.name.startsWith("pre-tour")) return;
          // Find tour name by splitting pre-tour name
          const pfTourName = pfTourCompleted.name.split("_")[1];
          // Find Tour id by name
          const pfTour = api.tours.getTours().find((tour: any) => tour.name === pfTourName);
          // Start the next tour
          if (pfTour && pfTour.id) {
            api.tours.tryStartTour(pfTour.id);
            if (isSmallScreen) {
              dispatch(openDrawer({ drawerOpen: false }));
            }
          } else {
            // Send email to user that the tour can not be found
            sendEmail(
              ERROR_REPORTING_EMAIL,
              "[Error PF] Product Fruits tour not found",
              `Tour ${pfTourName} not found`
            );
          }
        });
      }
      // Only have chat on the home-page
      if (location.pathname === "/home-page") {
        Crisp.chat.show();
        // The modal fills the screen
        // if (isMobile) {
        //    setTimeout(() => {
        //       // api.button.close();
        //       // api.setIsButtonOpen(false);
        //    }, 20);
        // }
      } else {
        // api.button.hide();
        Crisp.chat.hide();
        api.button.close();
      }
    },

    [location]
  );

  // --- Event handlers --- //
  const handleNext = () => {
    if (!user || !user.id) return;
    // Update onboarding object on cloud
    const curStep = activeStep + 1;
    let data: OnboardingObject = { step: curStep };
    if (steps[activeStep].id === "about-us") {
      data = {
        ...data,
        learnAboutUsAnswer: {
          answer: learnAboutUsAnswerRef.current.answer ? learnAboutUsAnswerRef.current.answer : "",
        },
      };
    } else if (steps[activeStep].id === "company") {
      data = {
        ...data,
        businessRegNoAndName: {
          regNo: businessRegNoRef.current.businessNo ? businessRegNoRef.current.businessNo : "",
          name: businessRegNoRef.current.data.name ? businessRegNoRef.current.data.name : "",
          businessExists: businessRegNoRef.current.businessExists,
        },
      };
    } else if (steps[activeStep].id === "needs") {
      data = {
        ...data,
        questionaireAnswers: {
          ...questionnaireAnswersRef.current,
        },
      };
    }
    // Update user onboarding object
    updateUserOnboardingObject(user.id, data);
    // If business already exists  set the user onboarding on hold
    // And indicate that the user is deletable, so the org admin can create the team member
    if (businessRegNoRef.current.businessExists) {
      userOnHoldBusinessExists.current = true;
      updateUserSettings(user.id, { deletable: true });
      updateUserOnboardingObject(user.id, { step: activeStep });
      return;
    }

    // Create org on firestore
    if (steps[activeStep].id === "company") {
      const bizNum = businessRegNoRef.current.businessNo;
      const bizData = businessRegNoRef.current.data;
      updateOrganization(
        null,
        {
          id: null,
          name: bizData.name ? bizData.name : "",
          businessRegNo: bizNum,
          address: bizData.address ? bizData.address : "",
          zipcode: bizData.zipcode ? bizData.zipcode : "",
          city: bizData.city ? bizData.city : "",
          users: [],
          contacts: [],
        },
        { ...bizData }
      );
    }

    setActiveStep(activeStep + 1);
    setErrorIndex(null);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFinish = async () => {
    if (!user || !user.id) return;
    await updateProfile({ ...user, onboarded: true }, false);
    // Navigate to the home page and start product fruits tour
    if (userOrg && userOrg.subscription && userOrg.subscription.isOrgToOrgSubscription) {
      navigate("/home-page", { replace: true });
    } else {
      navigate(getProductFruitsTourUrl("welcome"), { replace: true });
    }    
    setLearnAboutUsAnswer({});
    setActiveStep(0);
  };

  // --- Render --- //
  // Check if user exists, is already onboarded, or anonymous
  if (!user || user.authType === "anonymous") return null;
  // --- Product fruits JSX --- //
  if (!loadingTimerState && user && user.onboarded && userPFRef.current) {
    if (showProductFruits) {
      // Set language. If da set da, all other to english
      const languge = i18n === "da" ? "da" : "en";
      return (
        <ProductFruits
          workspaceCode={PF_WORKSPACE_CODE}
          language={languge}
          user={userPFRef.current}
          lifeCycle="unmount"
          config={{
            // disableLocationChangeDetection: true,
            customNavigation: {
              use: true,
              navigate: (url) => {
                navigate(url);
              },
              onGet() {
                // Always must start with https:// !!!
                return window.location.href;
              },
            },
          }}
        />
      );
    } else return null;
  } else if (user && user.onboarded) {
    // dialogOpen = false;
    return null;
  }
  // --- Onboarding JSX --- //
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
        backgroundColor: "white",
      }}
    >
      <Dialog open={true} maxWidth="sm" sx={{ "& .MuiDialog-paper": { width: "100%" } }}>
        <Box sx={{ p: !matchDownMD ? 1 : 0 }}>
          <DialogTitle>{translate("onboarding-main-container-title")}</DialogTitle>

          <Divider />

          <DialogContent>
            {/* STEPPER */}
            <Stepper activeStep={activeStep} sx={{ pt: 2, pb: 4 }}>
              {steps.map((step, index) => {
                const labelProps: { error?: boolean; optional?: ReactNode } = {};

                if (index === errorIndex) {
                  labelProps.error = true;
                }

                return (
                  <Step key={step.label}>
                    {index === activeStep ? (
                      <StepLabel {...labelProps}>{step.label}</StepLabel>
                    ) : (
                      <StepLabel {...labelProps}>{`${step.label.substring(0, 5)} ...`}</StepLabel>
                    )}
                  </Step>
                );
              })}
            </Stepper>

            <Divider />
          </DialogContent>

          {/* CONTENT */}
          {activeStep === steps.length ? (
            <>
              {/* LAST PAGE */}
              <DialogContent>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {translate("onboarding-final-title")}
                </Typography>

                <Typography variant="h6" sx={{ mb: 3, whiteSpace: "pre-line" }} component="div">
                  <div>{translate("onboarding-final-suggestions-1-new")}</div>
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 500, mb: 3, whiteSpace: "pre-line" }}>
                  {translate("onboarding-final-text")}
                </Typography>
              </DialogContent>

              <Divider />
            </>
          ) : (
            <>
              {getStepContent(
                activeStep,
                handleNext,
                handleBack,
                setErrorIndex,
                learnAboutUsAnswer,
                setLearnAboutUsAnswer,
                businessRegNo,
                setBusinessRegNo,
                questionnaireAnswers,
                setQuestionnaireAnswers,
                activeStep <= disableBackStep.current,
                userOnHoldBusinessExists.current
              )}
            </>
          )}

          {/* ACTION */}
          {activeStep === steps.length ? (
            <>
              {/* LAST PAGE */}
              <DialogActions>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{ width: 1, pl: 1.5, pr: 1.5, py: 0.75 }}
                >
                  <Button
                    variant="contained"
                    loading={false}
                    disabled={false}
                    onClick={handleFinish}
                    sx={{ bgcolor: "primary.light" }}
                  >
                    {translate("get-started")}
                  </Button>
                </Stack>
              </DialogActions>
            </>
          ) : (
            <>
              {/* FIRST PAGE AND SECOND TO LAST PAGE */}
              {(activeStep === steps.length || activeStep === 0) && (
                <DialogActions>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={activeStep !== 0 ? "space-between" : "flex-end"}
                    sx={{ width: 1, pl: 1.5, pr: 1.5, py: 0.75 }}
                  >
                    {activeStep !== 0 && activeStep > disableBackStep.current && (
                      <Button color="secondary" disabled={false} onClick={handleBack}>
                        {translate("back")}
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      loading={false}
                      disabled={false}
                      onClick={handleNext}
                      sx={{ bgcolor: "primary.light" }}
                    >
                      {activeStep === steps.length - 1 ? translate("get-started") : translate("next")}
                    </Button>
                  </Stack>
                </DialogActions>
              )}
            </>
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default Onboarding;
