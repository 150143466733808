export function addFacebookPixel(pixelId: string): void {
  // Initialize the Facebook Pixel
  (window as any).fbq =
    (window as any).fbq ||
    function () {
      (window as any).fbq.callMethod
        ? (window as any).fbq.callMethod.apply((window as any).fbq, arguments)
        : (window as any).fbq.queue.push(arguments);
    };

  if (!(window as any)._fbq) (window as any)._fbq = (window as any).fbq;

  (window as any).fbq.loaded = true;
  (window as any).fbq.version = "2.0";
  (window as any).fbq.queue = [];

  // Create the script tag
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = "https://connect.facebook.net/en_US/fbevents.js";

  // Append the script tag to the DOM
  const firstScript = document.getElementsByTagName("script")[0];
  firstScript.parentNode?.insertBefore(script, firstScript);

  // Initialize the pixel with the provided ID
  (window as any).fbq("init", pixelId);
  (window as any).fbq("track", "PageView");

  // Create the noscript tag
  const noscript = document.createElement("noscript");
  noscript.innerHTML = `
      <img height="1" width="1" style="display:none" 
      src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />
    `;
  document.body.appendChild(noscript);
}

export function trackFacebookEvent(
  pixelId: string,
  event: string,
  eventData: Record<string, any> = {}
): void {
  const baseUrl = `https://www.facebook.com/tr?id=${pixelId}&ev=${event}&noscript=1`;

  // Serialize event data into query parameters
  const eventParams = Object.entries(eventData)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");

  // Combine base URL with event parameters
  const trackingUrl = `${baseUrl}&${eventParams}`;

  // Create an Image object to send the tracking request
  const img = new Image();
  img.src = trackingUrl;
}

export function trackFacebookEventWithUrl(
  pixelId: string,
  event: string,
  virtualUrl: string,
  eventData: Record<string, any> = {}
): void {
  const baseUrl = `https://www.facebook.com/tr?id=${pixelId}&ev=${event}&noscript=1`;

  // Add the virtual URL to event data
  const extendedEventData = {
    ...eventData,
    dl: virtualUrl, // 'dl' is the parameter for document location (URL)
  };

  // Serialize event data into query parameters
  const eventParams = Object.entries(extendedEventData)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&");

  // Combine base URL with event parameters
  const trackingUrl = `${baseUrl}&${eventParams}`;

  // Create an Image object to send the tracking request
  const img = new Image();
  img.src = trackingUrl;
}
