// Add LinkedIn Insight Tag to the website
export function addLinkedInInsightTag(partnerId: string): void {
  // Set LinkedIn partner ID
  (window as any)._linkedin_partner_id = partnerId;
  (window as any)._linkedin_data_partner_ids = (window as any)._linkedin_data_partner_ids || [];
  (window as any)._linkedin_data_partner_ids.push(partnerId);

  // Create the script tag
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";

  // Create the noscript tag
  const noscript = document.createElement("noscript");
  noscript.innerHTML = `
    <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif" />
  `;

  // Append the script and noscript tags to the DOM
  const firstScript = document.getElementsByTagName("script")[0];
  firstScript.parentNode?.insertBefore(script, firstScript);
  document.body.appendChild(noscript);
}

// Create virtual url visit event for LinkedIn
export function trackLinkedInVirtualPageView(partnerId: string, url: string): void {
  const customUrl = encodeURIComponent(url);
  const img = new Image();
  img.src = `https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif&url=${customUrl}`;
}
