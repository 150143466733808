import { useEffect } from "react";

// Project import
import { forslerPartnerId } from "config/servicesConfig/linkedIn";
import { forslerPixelId } from "config/servicesConfig/facebook";
import { addLinkedInInsightTag } from "services/linkedIn/linkedIn";
import { addFacebookPixel } from "services/meta/facebook";

const useSoMeAccounts = () => {
    useEffect(() => {
        // Setup accounts
        addLinkedInInsightTag(forslerPartnerId);
        addFacebookPixel(forslerPixelId);
    }, []);
};

export default useSoMeAccounts;